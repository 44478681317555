import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import {
  CheckboxVisibility,
  DetailsList,
  DetailsListLayoutMode,
  DetailsRow,
  IColumn,
  IDetailsListProps,
  IDetailsRowStyles,
  Selection,
  SelectionMode,
} from '@fluentui/react/lib/DetailsList';
import * as React from 'react';
import { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { IconButton, Link, PrimaryButton, TooltipHost } from '@fluentui/react';

export interface IApplicationQualifier {
  key: string;
  name: string;
  highLevelQualifier: string;
  type: string;
}

const classNames = mergeStyleSets({
  fileIconHeaderIcon: {
    padding: 0,
    fontSize: '16px',
  },
  fileIconCell: {
    textAlign: 'center',
    selectors: {
      '&:before': {
        content: '.',
        display: 'inline-block',
        verticalAlign: 'middle',
        height: '100%',
        width: '0px',
        visibility: 'hidden',
      },
    },
  },
  fileIconImg: {
    verticalAlign: 'middle',
    maxHeight: '16px',
    maxWidth: '16px',
  },
  controlWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  exampleToggle: {
    display: 'inline-block',
    marginBottom: '10px',
    marginRight: '30px',
  },
  selectionDetails: {
    marginBottom: '20px',
  },
});

export interface IDataLIstV2 {
  DataRowItems: IApplicationQualifier[];
  onDelete(key: string): void;
}

const DatalistV2: FunctionComponent<IDataLIstV2> = ({
  DataRowItems,
  onDelete,
}): ReactElement => {
  const [items, setItems] = useState(DataRowItems);
  const [isCompactMode, setIsCompactMode] = useState(true);
  const [columns, setColumns] = useState([] as IColumn[]);
  const [selectionDetails, setSelectionDetails] = useState(0);

  const getKey = (item: IApplicationQualifier): string => {
    return item.key;
  };

  useEffect(() => {
    setIsCompactMode(true);
    setColumns([
      {
        key: 'column1',
        name: 'Name',
        fieldName: 'name',
        minWidth: 210,
        maxWidth: 400,
        isRowHeader: true,
        isResizable: true,
        data: 'string',
        isPadded: true,
      },
      {
        key: 'column2',
        name: 'High Level Qualifiers',
        fieldName: 'highLevelQualifier',
        minWidth: 210,
        maxWidth: 400,
        isRowHeader: true,
        isResizable: true,
        data: 'string',
        isPadded: true,
      },
      {
        key: 'column3',
        name: 'Type',
        fieldName: 'type',
        minWidth: 210,
        maxWidth: 400,
        isRowHeader: true,
        isResizable: true,
        data: 'string',
        isPadded: true,
      },
      {
        key: 'column4',
        name: ' ',
        ariaLabel:
          'Column operations for File type, Press to sort on File type',
        // iconName: 'Delete',
        isIconOnly: true,
        fieldName: 'name',
        minWidth: 51,
        maxWidth: 51,
        onRenderHeader: () => {
          return null;
        },
        // eslint-disable-next-line react/no-unstable-nested-components
        onRender: (item: IApplicationQualifier): ReactElement => (
          <TooltipHost
            content={`Delete application ${item.name} from discovery list`}
          >
            <IconButton
              style={{
                width: '51px',
                maxHeight: '21px',
                margin: '0px',
                padding: '0px',
              }}
              data-key={item.key}
              iconProps={{ iconName: 'Delete' }}
              aria-label="Delete"
              onClick={(i) => {
                onDelete(item.key);
              }}
            />
          </TooltipHost>
        ),
      },
    ]);
  }, []);

  useEffect(() => {
    setItems(DataRowItems);
  }, [DataRowItems]);

  const selection = new Selection({
    onSelectionChanged: () => {
      const cloneOfItems = items.map((i) => i);
      // setItems(cloneOfItems);
      setSelectionDetails(selection.getSelectedCount());
    },
  });

  const onRenderRow: IDetailsListProps['onRenderRow'] = (props) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      if (props.itemIndex % 2 === 0) {
        // Every other row renders with a different background color
        // customStyles.root = { backgroundColor: '#FAF5F0' };
      } else {
        customStyles.root = { backgroundColor: '#edede9' };
      }

      return <DetailsRow {...props} styles={customStyles} />;
    }
    return null;
  };

  return (
    <DetailsList
      items={items}
      checkboxVisibility={CheckboxVisibility.hidden}
      compact={isCompactMode}
      columns={columns}
      selectionMode={SelectionMode.multiple}
      getKey={getKey}
      setKey="multiple"
      layoutMode={DetailsListLayoutMode.justified}
      isHeaderVisible
      selection={selection}
      onRenderRow={onRenderRow}
      selectionPreservedOnEmptyClick
      ariaLabelForSelectionColumn="Toggle selection"
      ariaLabelForSelectAllCheckbox="Toggle selection for all items"
      checkButtonAriaLabel="select row"
    />
  );
};

export default DatalistV2;
