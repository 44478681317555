import './assess-mainframe.scss';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FunctionComponent, useEffect } from 'react';
import { LayerHost } from '@fluentui/react/lib/Layer';
import { useNavigate } from 'react-router-dom';
import {
  DefaultButton,
  IStackTokens,
  mergeStyles,
  PrimaryButton,
  Stack,
  Text,
} from '@fluentui/react';

import { useId } from '@fluentui/react-hooks';
import { getTheme } from '@fluentui/react/lib/Styling';
import { IBreadcrumbItem } from '@fluentui/react/lib/Breadcrumb';
import BreadcrumbService from '../../service/breadcrumb.service';

const theme = getTheme();
const layerHostClass = mergeStyles({
  position: 'relative',
  marginLeft: '.0%',
  marginRight: '.0%',
  height: '80vh',
  overflow: 'hidden',
  width: '100%',
  border: '1px solid #ccc',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.whiteTranslucent40,
});

const contentHeaderClass = mergeStyles([
  {
    backgroundColor: theme.palette.black,
    color: theme.palette.white,
    lineHeight: '50px',
    padding: '0 5px',
  },
]);

const contentFooterClass = mergeStyles([
  {
    backgroundColor: theme.palette.blackTranslucent40,
    color: theme.palette.white,
    height: '50px',
    padding: '5px 20px',
    borderTop: '1px solid #ccc',
    display: 'flex',
  },
]);

const contentFooterTotalClass = mergeStyles([
  {
    backgroundColor: theme.palette.black,
    color: theme.palette.white,
    padding: '5px 20px',
    borderTop: '1px solid #ccc',
    display: 'flex',
  },
]);

const contentFooterSubTotalClass = mergeStyles([
  {
    backgroundColor: theme.palette.black,
    color: theme.palette.white,
    padding: '5px 20px',
    borderTop: '1px solid #ccc',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '5px',
  },
]);

const contentClass = mergeStyles([
  {
    backgroundColor: theme.semanticColors.bodyDivider,
    color: theme.palette.white,
    lineHeight: '50px',
    padding: '0 10px',
    // borderBottom: "1px solid #ABB5FF",
  },
]);

const contentMainFrameTitleClass = mergeStyles([
  {
    backgroundColor: theme.palette.black,
    color: theme.palette.white,
    lineHeight: '50px',
    padding: '0 10px',
    // borderBottom: "1px solid #9FB5D7",
  },
]);

const AccessMainframeComponent: FunctionComponent = () => {
  const layerHostId = useId('layerHost');
  const stackTokens: IStackTokens = { childrenGap: 51 };
  const wrapStackTokens: IStackTokens = { childrenGap: 5 };
  const navigate = useNavigate();
  const aboutBlob =
    "Azure Blob storage is Microsoft's object storage\n" +
    '                          solution for the cloud. Blob storage is optimized for\n' +
    '                          storing massive amounts of unstructured data.\n' +
    "                          Unstructured data is data that doesn't adhere to a\n" +
    '                          particular data model.';
  const aboutTable =
    'Azure Table storage is a service that stores non-relational structured' +
    ' data (also known as structured NoSQL data) in the cloud, providing a key/attribute' +
    ' store with a schemaless design. Because Table storage' +
    " is schemaless, it's easy to adapt your data.\n";
  const aboutSQL =
    'Microsoft Azure SQL Database is a managed cloud database ' +
    'provided as part of Microsoft Azure. A cloud database is a database' +
    ' that runs on a cloud computing platform. Managed database' +
    ' services take care of scalability, backup, etc ';

  const aboutAPIGateWay =
    'An API gateway sits between clients and services.' +
    ' It acts as a reverse proxy, routing requests from clients to ' +
    'services. It may also perform various cross-cutting tasks such ' +
    'as authentication, SSL termination, and rate limiting.';

  const aboutLoadBalancer =
    'An Azure load balancer is a Layer-4 (TCP, UDP) load balancer' +
    ' that provides high availability by distributing incoming traffic' +
    ' among healthy VMs. A load balancer health probe monitors a given' +
    ' port on each VM and only distributes traffic to an operational VM';

  const aboutAppService =
    'Azure Web Apps is a cloud computing based platform for hosting websites,' +
    ' created and operated by Microsoft. It allows publishing Web apps running' +
    ' on multiple frameworks and different programming languages, including ' +
    'Microsoft proprietary and 3rd party.';

  const aboutApp =
    'Build web apps, services, and RESTful APIs with Azure App Service, a' +
    ' fully managed web hosting service. A range of plans help meet the' +
    ' needs of any application.';

  const aboutVmB2ms =
    'The B-series VMs can be deployed on a variety of hardware types and' +
    ' processors, so competitive bandwidth allocation is provided. B-series' +
    ' VMs are ideal for like web servers, proof of concepts, small databases' +
    ' and development build environments.';

  const aboutDisk =
    'Azure managed disks are block-level storage volumes that are managed by' +
    ' Azure and used with Azure Virtual Machines. Managed disks are like a' +
    ' physical disk in an on-premises server but, virtualized.';

  const aboutMonitoring =
    'Azure Monitor Logs is a log data platform that collects activity' +
    ' logs and resource logs along with other monitoring data to provide' +
    ' deep analysis across your entire set of resources.';

  const aboutAppInsight =
    'Application Insights is an extension of Azure Monitor and provides' +
    ' Application Performance Monitoring (also known as “APM”) features.';

  const aboutSiteRecovery =
    'Azure Site Recovery offers ease of deployment, cost effectiveness,' +
    ' and dependability. Deploy replication, fail-over, and recovery' +
    ' processes through Site Recovery to help keep your applications' +
    ' running during planned and unplanned outages.';

  const itemsBC: IBreadcrumbItem[] = [
    {
      text: 'Home',
      key: 'discover',
      onClick: () => {
        navigate('/home-assess');
      },
    },
    {
      text: 'Assess',
      key: 'Assess_mainframe',
      onClick: () => {
        console.log('');
      },
    },
  ];

  const detailsItemStyle = {
    display: 'inline-block',
    height: '130px',
    overflow: 'hidden',
  };

  useEffect(() => {
    BreadcrumbService.setValue(itemsBC);
  }, []);

  return (
    <section
      style={{
        flex: 1,
        borderTop: '1px solid #ccc',
        display: 'flex',
        backgroundColor: '#F4F5F6',
      }}
    >
      <LayerHost id={layerHostId} className={layerHostClass}>
        <div className={contentHeaderClass}>
          <div style={{ display: 'flex', flex: 1 }}>
            <div
              style={{
                display: 'flex',
                flex: 0.5,
              }}
            >
              <Text
                variant="xLargePlus"
                style={{
                  color: 'white',
                  fontWeight: 'bold',
                  marginRight: '5px',
                }}
              >
                Azure
              </Text>
              <Text
                variant="small"
                style={{ color: 'white', marginTop: '5px', marginLeft: '2px' }}
              >
                components / service
              </Text>
            </div>
          </div>
        </div>
        <div
          style={{
            overflowY: 'scroll',
            overflowX: 'hidden',
          }}
        >
          <div>
            <div
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                width: '100%',
                height: '350px',
                overflow: 'hidden',
              }}
            >
              <div
                style={{
                  flex: 0.8,
                  display: 'flex',
                  width: '100%',
                  height: '350px',
                }}
              >
                <div className="mainframe-component">
                  <div className={contentMainFrameTitleClass}>
                    <Text
                      variant="xLargePlus"
                      style={{ fontWeight: 'bold', color: 'white' }}
                    >
                      CPC
                    </Text>
                    <Text
                      variant="medium"
                      style={{ fontWeight: 'bold', color: 'white' }}
                    >
                      {' Mainframe com.'}
                    </Text>
                  </div>
                  <div style={{ margin: '10px' }}>
                    <Text variant="xLarge">Details</Text>
                    <br />
                    <br />
                    <Text>
                      Mainframes have a central processor complex (CPC), which
                      may contain several different types of z/Architecture®
                      processors that can be used for slightly different
                      purposes
                    </Text>
                  </div>
                </div>
              </div>
              <div
                style={{
                  flex: 9.2,
                  display: 'flex',
                  width: '100%',
                  height: '350px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    overflowX: 'scroll',
                    overflowY: 'hidden',
                  }}
                >
                  <Stack
                    horizontalAlign="space-evenly"
                    verticalFill
                    horizontal
                    tokens={wrapStackTokens}
                    style={{
                      paddingLeft: '5px',
                    }}
                  >
                    <div className="access-azure-card">
                      <div className={contentClass}>
                        <Text variant="xLargePlus">VM: B2MS</Text>
                        <Text variant="medium">{' (2 vCPU 16 GB) '}</Text>
                      </div>
                      <div style={{ margin: '10px' }}>
                        <Text variant="xLarge">Details</Text>
                        <br />
                        <br />
                        <Text style={detailsItemStyle}>{aboutVmB2ms}</Text>
                        <div style={{ alignSelf: 'flex-end' }}>
                          <hr style={{ borderTop: 'solid 1px' }} />
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Text
                              variant="large"
                              style={{ width: '100%', fontWeight: 'bold' }}
                            >
                              SKU cost
                            </Text>
                            <Text
                              variant="large"
                              style={{
                                textAlign: 'right',
                                width: '100%',
                                fontWeight: 'bold',
                              }}
                            >
                              $66.58
                            </Text>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Text
                              variant="large"
                              style={{ width: '100%', fontWeight: 'bold' }}
                            >
                              Count
                            </Text>
                            <Text
                              variant="large"
                              style={{
                                textAlign: 'right',
                                width: '100%',
                                fontWeight: 'bold',
                              }}
                            >
                              1
                            </Text>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Text
                              variant="large"
                              style={{ width: '100%', fontWeight: 'bold' }}
                            >
                              Est. monthly cost
                            </Text>
                            <Text
                              variant="large"
                              style={{
                                textAlign: 'right',
                                width: '100%',
                                fontWeight: 'bold',
                              }}
                            >
                              $66.58
                            </Text>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="access-azure-card">
                      <div className={contentClass}>
                        <Text variant="xLargePlus">Azure Monitoring</Text>
                      </div>
                      <div style={{ margin: '10px' }}>
                        <Text variant="xLarge">Details</Text>
                        <br />
                        <br />
                        <Text style={detailsItemStyle}>{aboutMonitoring}</Text>
                        <div style={{ alignSelf: 'flex-end' }}>
                          <hr style={{ borderTop: 'solid 1px' }} />
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Text
                              variant="large"
                              style={{ width: '100%', fontWeight: 'bold' }}
                            >
                              SKU cost
                            </Text>
                            <Text
                              variant="large"
                              style={{
                                textAlign: 'right',
                                width: '100%',
                                fontWeight: 'bold',
                              }}
                            >
                              $200.00
                            </Text>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Text
                              variant="large"
                              style={{ width: '100%', fontWeight: 'bold' }}
                            >
                              Count
                            </Text>
                            <Text
                              variant="large"
                              style={{
                                textAlign: 'right',
                                width: '100%',
                                fontWeight: 'bold',
                              }}
                            >
                              1
                            </Text>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Text
                              variant="large"
                              style={{ width: '100%', fontWeight: 'bold' }}
                            >
                              Est. monthly cost
                            </Text>
                            <Text
                              variant="large"
                              style={{
                                textAlign: 'right',
                                width: '100%',
                                fontWeight: 'bold',
                              }}
                            >
                              $200.00
                            </Text>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="access-azure-card">
                      <div className={contentClass}>
                        <Text variant="xLargePlus">App Insights</Text>
                      </div>
                      <div style={{ margin: '10px' }}>
                        <Text variant="xLarge">Details</Text>
                        <br />
                        <br />
                        <Text style={detailsItemStyle}>{aboutAppInsight}</Text>
                        <div style={{ alignSelf: 'flex-end' }}>
                          <hr style={{ borderTop: 'solid 1px' }} />
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Text
                              variant="large"
                              style={{ width: '100%', fontWeight: 'bold' }}
                            >
                              SKU cost
                            </Text>
                            <Text
                              variant="large"
                              style={{
                                textAlign: 'right',
                                width: '100%',
                                fontWeight: 'bold',
                              }}
                            >
                              $0.00
                            </Text>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Text
                              variant="large"
                              style={{ width: '100%', fontWeight: 'bold' }}
                            >
                              Count
                            </Text>
                            <Text
                              variant="large"
                              style={{
                                textAlign: 'right',
                                width: '100%',
                                fontWeight: 'bold',
                              }}
                            >
                              1
                            </Text>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Text
                              variant="large"
                              style={{ width: '100%', fontWeight: 'bold' }}
                            >
                              Est. monthly cost
                            </Text>
                            <Text
                              variant="large"
                              style={{
                                textAlign: 'right',
                                width: '100%',
                                fontWeight: 'bold',
                              }}
                            >
                              $0.00
                            </Text>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="access-azure-card">
                      <div className={contentClass}>
                        <Text variant="xLargePlus">Azure Site Recovery</Text>
                      </div>
                      <div style={{ margin: '10px' }}>
                        <Text variant="xLarge">Details</Text>
                        <br />
                        <br />
                        <Text style={detailsItemStyle}>
                          {aboutSiteRecovery}
                        </Text>
                        <div style={{ alignSelf: 'flex-end' }}>
                          <hr style={{ borderTop: 'solid 1px' }} />
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Text
                              variant="large"
                              style={{ width: '100%', fontWeight: 'bold' }}
                            >
                              SKU cost
                            </Text>
                            <Text
                              variant="large"
                              style={{
                                textAlign: 'right',
                                width: '100%',
                                fontWeight: 'bold',
                              }}
                            >
                              $25.00
                            </Text>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Text
                              variant="large"
                              style={{ width: '100%', fontWeight: 'bold' }}
                            >
                              Count
                            </Text>
                            <Text
                              variant="large"
                              style={{
                                textAlign: 'right',
                                width: '100%',
                                fontWeight: 'bold',
                              }}
                            >
                              2
                            </Text>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Text
                              variant="large"
                              style={{ width: '100%', fontWeight: 'bold' }}
                            >
                              Est. monthly cost
                            </Text>
                            <Text
                              variant="large"
                              style={{
                                textAlign: 'right',
                                width: '100%',
                                fontWeight: 'bold',
                              }}
                            >
                              $50.00
                            </Text>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="access-azure-card" />
                  </Stack>
                </div>
              </div>
            </div>
            <div className={contentFooterSubTotalClass}>
              <Text
                variant="xLarge"
                style={{ color: 'white', fontWeight: 'bold' }}
              >
                Sub total
              </Text>
              <Text
                variant="xLarge"
                style={{ color: 'white', fontWeight: 'bold' }}
              >
                $316.58
              </Text>
            </div>
          </div>
          <div>
            <div
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                width: '100%',
                height: '350px',
                overflow: 'hidden',
              }}
            >
              <div
                style={{
                  flex: 0.8,
                  display: 'flex',
                  width: '100%',
                  height: '350px',
                }}
              >
                <div className="mainframe-component">
                  <div className={contentMainFrameTitleClass}>
                    <Text
                      variant="xLargePlus"
                      style={{ fontWeight: 'bold', color: 'white' }}
                    >
                      STORAGE
                    </Text>
                    <Text
                      variant="medium"
                      style={{ fontWeight: 'bold', color: 'white' }}
                    >
                      {' Mainframe com.'}
                    </Text>
                  </div>
                  <div style={{ margin: '10px' }}>
                    <Text variant="xLarge">Details</Text>
                    <br />
                    <br />
                    <Text>
                      Physical storage located on the mainframe processor
                      itself. This is called processor storage, real storage or
                      central storage; think of it as memory for the mainframe.
                      Physical storage external to the mainframe, including
                      storage on direct access devices, such as disk drives and
                      tape drives.
                    </Text>
                  </div>
                </div>
              </div>
              <div
                style={{
                  flex: 9.2,
                  display: 'flex',
                  width: '100%',
                  height: '350px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    overflowX: 'scroll',
                    overflowY: 'hidden',
                  }}
                >
                  <Stack
                    horizontalAlign="space-evenly"
                    verticalFill
                    horizontal
                    tokens={wrapStackTokens}
                    style={{
                      paddingLeft: '5px',
                    }}
                  >
                    <div className="access-azure-card">
                      <div className={contentClass}>
                        <Text variant="xLargePlus">VM DS3_v4</Text>
                        <Text variant="medium">{' (4 vCPU) '}</Text>
                      </div>
                      <div style={{ margin: '10px' }}>
                        <Text variant="xLarge">Details</Text>
                        <br />
                        <br />
                        <Text style={detailsItemStyle}>
                          The Dv4-series sizes offer a combination of vCPU,
                          memory and remote storage options for most production
                          workloads.
                        </Text>
                        <div style={{ alignSelf: 'flex-end' }}>
                          <hr style={{ borderTop: 'solid 1px' }} />
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Text
                              variant="large"
                              style={{ width: '100%', fontWeight: 'bold' }}
                            >
                              SKU cost
                            </Text>
                            <Text
                              variant="large"
                              style={{
                                textAlign: 'right',
                                width: '100%',
                                fontWeight: 'bold',
                              }}
                            >
                              $124.08
                            </Text>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Text
                              variant="large"
                              style={{ width: '100%', fontWeight: 'bold' }}
                            >
                              Count
                            </Text>
                            <Text
                              variant="large"
                              style={{
                                textAlign: 'right',
                                width: '100%',
                                fontWeight: 'bold',
                              }}
                            >
                              3
                            </Text>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Text
                              variant="large"
                              style={{ width: '100%', fontWeight: 'bold' }}
                            >
                              Est. monthly cost
                            </Text>
                            <Text
                              variant="large"
                              style={{
                                textAlign: 'right',
                                width: '100%',
                                fontWeight: 'bold',
                              }}
                            >
                              $372.24
                            </Text>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="access-azure-card">
                      <div className={contentClass}>
                        <Text variant="xLargePlus">Disk</Text>
                        <Text variant="medium">{' P20 512GB 2300 IOPS '}</Text>
                      </div>
                      <div style={{ margin: '10px' }}>
                        <Text variant="xLarge">Details</Text>
                        <br />
                        <br />
                        <Text style={detailsItemStyle}>{aboutDisk}</Text>
                        <div style={{ alignSelf: 'flex-end' }}>
                          <hr style={{ borderTop: 'solid 1px' }} />
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Text
                              variant="large"
                              style={{ width: '100%', fontWeight: 'bold' }}
                            >
                              SKU cost
                            </Text>
                            <Text
                              variant="large"
                              style={{
                                textAlign: 'right',
                                width: '100%',
                                fontWeight: 'bold',
                              }}
                            >
                              $66.56
                            </Text>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Text
                              variant="large"
                              style={{ width: '100%', fontWeight: 'bold' }}
                            >
                              Count
                            </Text>
                            <Text
                              variant="large"
                              style={{
                                textAlign: 'right',
                                width: '100%',
                                fontWeight: 'bold',
                              }}
                            >
                              3
                            </Text>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Text
                              variant="large"
                              style={{ width: '100%', fontWeight: 'bold' }}
                            >
                              Est. monthly cost
                            </Text>
                            <Text
                              variant="large"
                              style={{
                                textAlign: 'right',
                                width: '100%',
                                fontWeight: 'bold',
                              }}
                            >
                              $199.68
                            </Text>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="access-azure-card">
                      <div className={contentClass}>
                        <Text variant="xLargePlus">VM E2s_v3</Text>
                        <Text variant="medium">{' (2 vCPU) '}</Text>
                      </div>
                      <div style={{ margin: '10px' }}>
                        <Text variant="xLarge">Details</Text>
                        <br />
                        <br />
                        <Text style={detailsItemStyle}>
                          Esv3-series instances run on the 3rd Generation Intel®
                          Xeon® Platinum 8370C (Ice Lake), Intel® Xeon® Platinum
                          8272CL (Cascade Lake) and Intel® Xeon.
                        </Text>
                        <div style={{ alignSelf: 'flex-end' }}>
                          <hr style={{ borderTop: 'solid 1px' }} />
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Text
                              variant="large"
                              style={{ width: '100%', fontWeight: 'bold' }}
                            >
                              SKU cost
                            </Text>
                            <Text
                              variant="large"
                              style={{
                                textAlign: 'right',
                                width: '100%',
                                fontWeight: 'bold',
                              }}
                            >
                              $82.64
                            </Text>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Text
                              variant="large"
                              style={{ width: '100%', fontWeight: 'bold' }}
                            >
                              Count
                            </Text>
                            <Text
                              variant="large"
                              style={{
                                textAlign: 'right',
                                width: '100%',
                                fontWeight: 'bold',
                              }}
                            >
                              2
                            </Text>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Text
                              variant="large"
                              style={{ width: '100%', fontWeight: 'bold' }}
                            >
                              Est. monthly cost
                            </Text>
                            <Text
                              variant="large"
                              style={{
                                textAlign: 'right',
                                width: '100%',
                                fontWeight: 'bold',
                              }}
                            >
                              $165.28
                            </Text>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="access-azure-card">
                      <div className={contentClass}>
                        <Text variant="xLargePlus">Disk</Text>
                        <Text variant="medium">{' P10: 128 GB 500 IOPS '}</Text>
                      </div>
                      <div style={{ margin: '10px' }}>
                        <Text variant="xLarge">Details</Text>
                        <br />
                        <br />
                        <Text style={detailsItemStyle}>{aboutDisk}</Text>
                        <div style={{ alignSelf: 'flex-end' }}>
                          <hr style={{ borderTop: 'solid 1px' }} />
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Text
                              variant="large"
                              style={{ width: '100%', fontWeight: 'bold' }}
                            >
                              SKU cost
                            </Text>
                            <Text
                              variant="large"
                              style={{
                                textAlign: 'right',
                                width: '100%',
                                fontWeight: 'bold',
                              }}
                            >
                              $17.92
                            </Text>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Text
                              variant="large"
                              style={{ width: '100%', fontWeight: 'bold' }}
                            >
                              Count
                            </Text>
                            <Text
                              variant="large"
                              style={{
                                textAlign: 'right',
                                width: '100%',
                                fontWeight: 'bold',
                              }}
                            >
                              3
                            </Text>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Text
                              variant="large"
                              style={{ width: '100%', fontWeight: 'bold' }}
                            >
                              Est. monthly cost
                            </Text>
                            <Text
                              variant="large"
                              style={{
                                textAlign: 'right',
                                width: '100%',
                                fontWeight: 'bold',
                              }}
                            >
                              $53.76
                            </Text>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="access-azure-card">
                      <div className={contentClass}>
                        <Text variant="xLargePlus">Azure Redis Cache</Text>
                      </div>
                      <div style={{ margin: '10px' }}>
                        <Text variant="xLarge">Details</Text>
                        <br />
                        <br />
                        <Text style={detailsItemStyle}>
                          Azure Cache for Redis is a fully managed, in-memory
                          cache that enables high-performance and scalable
                          architectures. Use it to create cloud or hybrid
                          deployments.
                        </Text>
                        <div style={{ alignSelf: 'flex-end' }}>
                          <hr style={{ borderTop: 'solid 1px' }} />
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Text
                              variant="large"
                              style={{ width: '100%', fontWeight: 'bold' }}
                            >
                              SKU cost
                            </Text>
                            <Text
                              variant="large"
                              style={{
                                textAlign: 'right',
                                width: '100%',
                                fontWeight: 'bold',
                              }}
                            >
                              $40.15
                            </Text>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Text
                              variant="large"
                              style={{ width: '100%', fontWeight: 'bold' }}
                            >
                              Count
                            </Text>
                            <Text
                              variant="large"
                              style={{
                                textAlign: 'right',
                                width: '100%',
                                fontWeight: 'bold',
                              }}
                            >
                              1
                            </Text>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Text
                              variant="large"
                              style={{ width: '100%', fontWeight: 'bold' }}
                            >
                              Est. monthly cost
                            </Text>
                            <Text
                              variant="large"
                              style={{
                                textAlign: 'right',
                                width: '100%',
                                fontWeight: 'bold',
                              }}
                            >
                              $40.15
                            </Text>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="access-azure-card" />
                  </Stack>
                </div>
              </div>
            </div>
            <div className={contentFooterSubTotalClass}>
              <Text
                variant="xLarge"
                style={{ color: 'white', fontWeight: 'bold' }}
              >
                Sub total
              </Text>
              <Text
                variant="xLarge"
                style={{ color: 'white', fontWeight: 'bold' }}
              >
                $831.11
              </Text>
            </div>
          </div>
          <div>
            <div
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                width: '100%',
                height: '350px',
                overflow: 'hidden',
              }}
            >
              <div
                style={{
                  flex: 0.8,
                  display: 'flex',
                  width: '100%',
                  height: '350px',
                }}
              >
                <div className="mainframe-component">
                  <div className={contentMainFrameTitleClass}>
                    <Text
                      variant="xLargePlus"
                      style={{ fontWeight: 'bold', color: 'white' }}
                    >
                      NETWORK
                    </Text>
                    <Text
                      variant="medium"
                      style={{ fontWeight: 'bold', color: 'white' }}
                    >
                      {' Mainframe com.'}
                    </Text>
                  </div>
                  <div style={{ margin: '10px' }}>
                    <Text variant="xLarge">Details</Text>
                    <br />
                    <br />
                    <Text>
                      The mainframe is capable of serving a large number of
                      network nodes geographically dispersed across the world
                      while simultaneously handling a high volume of input and
                      output operations to disk storage, printers, and other
                      attached computers. Mainframe architecture includes a
                      variety of network capabilities.
                    </Text>
                  </div>
                </div>
              </div>
              <div
                style={{
                  flex: 9.2,
                  display: 'flex',
                  width: '100%',
                  height: '350px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    overflowX: 'scroll',
                    overflowY: 'hidden',
                  }}
                >
                  <Stack
                    horizontalAlign="space-evenly"
                    verticalFill
                    horizontal
                    tokens={wrapStackTokens}
                    style={{
                      paddingLeft: '5px',
                    }}
                  >
                    <div className="access-azure-card">
                      <div className={contentClass}>
                        <Text variant="xLargePlus">App. Gateway</Text>
                        <Text variant="medium">{' (WAF) '}</Text>
                      </div>
                      <div style={{ margin: '10px' }}>
                        <Text variant="xLarge">Details</Text>
                        <br />
                        <br />
                        <Text style={detailsItemStyle}>{aboutAPIGateWay}</Text>
                        <div style={{ alignSelf: 'flex-end' }}>
                          <hr style={{ borderTop: 'solid 1px' }} />
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Text
                              variant="large"
                              style={{ width: '100%', fontWeight: 'bold' }}
                            >
                              SKU cost
                            </Text>
                            <Text
                              variant="large"
                              style={{
                                textAlign: 'right',
                                width: '100%',
                                fontWeight: 'bold',
                              }}
                            >
                              $103.02
                            </Text>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Text
                              variant="large"
                              style={{ width: '100%', fontWeight: 'bold' }}
                            >
                              Count
                            </Text>
                            <Text
                              variant="large"
                              style={{
                                textAlign: 'right',
                                width: '100%',
                                fontWeight: 'bold',
                              }}
                            >
                              2
                            </Text>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Text
                              variant="large"
                              style={{ width: '100%', fontWeight: 'bold' }}
                            >
                              Est. monthly cost
                            </Text>
                            <Text
                              variant="large"
                              style={{
                                textAlign: 'right',
                                width: '100%',
                                fontWeight: 'bold',
                              }}
                            >
                              $206.04
                            </Text>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="access-azure-card">
                      <div className={contentClass}>
                        <Text variant="xLargePlus">Load Balancer</Text>
                        <Text variant="medium">{' (Standard) '}</Text>
                      </div>
                      <div style={{ margin: '10px' }}>
                        <Text variant="xLarge">Details</Text>
                        <br />
                        <br />
                        <Text style={detailsItemStyle}>
                          {aboutLoadBalancer}
                        </Text>
                        <div style={{ alignSelf: 'flex-end' }}>
                          <hr style={{ borderTop: 'solid 1px' }} />
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Text
                              variant="large"
                              style={{ width: '100%', fontWeight: 'bold' }}
                            >
                              SKU cost
                            </Text>
                            <Text
                              variant="large"
                              style={{
                                textAlign: 'right',
                                width: '100%',
                                fontWeight: 'bold',
                              }}
                            >
                              $16.06
                            </Text>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Text
                              variant="large"
                              style={{ width: '100%', fontWeight: 'bold' }}
                            >
                              Count
                            </Text>
                            <Text
                              variant="large"
                              style={{
                                textAlign: 'right',
                                width: '100%',
                                fontWeight: 'bold',
                              }}
                            >
                              2
                            </Text>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Text
                              variant="large"
                              style={{ width: '100%', fontWeight: 'bold' }}
                            >
                              Est. monthly cost
                            </Text>
                            <Text
                              variant="large"
                              style={{
                                textAlign: 'right',
                                width: '100%',
                                fontWeight: 'bold',
                              }}
                            >
                              $32.12
                            </Text>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="access-azure-card">
                      <div className={contentClass}>
                        <Text variant="xLargePlus">App Service</Text>
                        <Text variant="medium">{' (Java) 2 Inst. '}</Text>
                      </div>
                      <div style={{ margin: '10px' }}>
                        <Text variant="xLarge">Details</Text>
                        <br />
                        <br />
                        <Text style={detailsItemStyle}>{aboutApp}</Text>
                        <div style={{ alignSelf: 'flex-end' }}>
                          <hr style={{ borderTop: 'solid 1px' }} />
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Text
                              variant="large"
                              style={{ width: '100%', fontWeight: 'bold' }}
                            >
                              SKU cost
                            </Text>
                            <Text
                              variant="large"
                              style={{
                                textAlign: 'right',
                                width: '100%',
                                fontWeight: 'bold',
                              }}
                            >
                              $147.46
                            </Text>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Text
                              variant="large"
                              style={{ width: '100%', fontWeight: 'bold' }}
                            >
                              Count
                            </Text>
                            <Text
                              variant="large"
                              style={{
                                textAlign: 'right',
                                width: '100%',
                                fontWeight: 'bold',
                              }}
                            >
                              2
                            </Text>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Text
                              variant="large"
                              style={{ width: '100%', fontWeight: 'bold' }}
                            >
                              Est. monthly cost
                            </Text>
                            <Text
                              variant="large"
                              style={{
                                textAlign: 'right',
                                width: '100%',
                                fontWeight: 'bold',
                              }}
                            >
                              $294.92
                            </Text>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="access-azure-card" />
                  </Stack>
                </div>
              </div>
            </div>
            <div className={contentFooterSubTotalClass}>
              <Text
                variant="xLarge"
                style={{ color: 'white', fontWeight: 'bold' }}
              >
                Sub total
              </Text>
              <Text
                variant="xLarge"
                style={{ color: 'white', fontWeight: 'bold' }}
              >
                $533.08
              </Text>
            </div>
          </div>
        </div>
        <div
          className={contentFooterTotalClass}
          style={{ display: 'inline-block', width: '100%' }}
        >
          <div style={{ float: 'left' }}>
            <Text
              variant="xLarge"
              style={{ color: 'white', fontWeight: 'bolder' }}
            >
              Total monthly cost
            </Text>
          </div>
          <div style={{ float: 'right', marginRight: '40px' }}>
            <Text
              variant="xLarge"
              style={{ color: 'white', fontWeight: 'bolder', float: 'right' }}
            >
              $1,680.77
            </Text>
          </div>
        </div>
        <div className={contentFooterClass}>
          <Stack
            horizontal
            tokens={stackTokens}
            style={{
              float: 'right',
              lineHeight: '50px',
              alignItems: 'center',
            }}
          >
            <DefaultButton
              text="Previous"
              onClick={() => {
                navigate('/home-assess');
              }}
              allowDisabledFocus
              style={{ height: '40px', fontSize: '18px' }}
            />

            <PrimaryButton
              text="Finish"
              allowDisabledFocus
              onClick={() => {
                navigate('/home-migrate');
              }}
              style={{ height: '40px', fontSize: '18px' }}
            />
          </Stack>
        </div>
      </LayerHost>
    </section>
  );
};

export default AccessMainframeComponent;
