import React, { FunctionComponent } from 'react';
import {
  AnimationClassNames,
  getTheme,
  mergeStyles,
  Text,
} from '@fluentui/react';

const theme = getTheme();
const contentClass = mergeStyles([
  {
    backgroundColor: theme.palette.black,
    color: theme.palette.white,
    lineHeight: '50px',
    padding: '0 20px',
  },
  AnimationClassNames.scaleUpIn100,
]);

const FooterComponent: FunctionComponent = () => {
  return (
    <footer>
      <div className={contentClass}>
        <Text variant="xLarge" style={{ color: 'white' }}>
          Cloudframe
        </Text>
        <Text variant="mediumPlus" style={{ color: 'white', float: 'right' }}>
          @Copyright 2022
        </Text>
      </div>
    </footer>
  );
};

export default FooterComponent;
