/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,@typescript-eslint/explicit-function-return-type,react-hooks/rules-of-hooks,@typescript-eslint/ban-ts-comment */
import './migrate-app.scss';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { LayerHost } from '@fluentui/react/lib/Layer';
import { useNavigate } from 'react-router-dom';
import {
  DefaultButton,
  Dropdown,
  IDropdownStyles,
  IShimmerElement,
  IShimmerStyles,
  IStackTokens,
  mergeStyles,
  PrimaryButton,
  ProgressIndicator,
  Shimmer,
  ShimmerElementType,
  ShimmerLine,
  Stack,
  Text,
  ThemeProvider,
} from '@fluentui/react';

import { useId } from '@fluentui/react-hooks';
import { getTheme } from '@fluentui/react/lib/Styling';

import { DownloadDocumentIcon, JavaLogoIcon } from '@fluentui/react-icons-mdl2';
import { IBreadcrumbItem } from '@fluentui/react/lib/Breadcrumb';
import BreadcrumbService from '../../service/breadcrumb.service';

const theme = getTheme();
const layerHostClass = mergeStyles({
  position: 'relative',
  marginLeft: '0%',
  marginRight: '0%',
  overflow: 'hidden',
  width: '100%',
  border: '1px solid #ccc',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.semanticColors.bodyBackground,
});

const contentHeaderClass = mergeStyles([
  {
    backgroundColor: theme.palette.black,
    color: theme.palette.white,
    lineHeight: '50px',
    padding: '0 15px',
  },
]);

const contentFooterClass = mergeStyles([
  {
    backgroundColor: theme.palette.blackTranslucent40,
    color: theme.palette.white,
    height: '50px',
    padding: '0 20px',
    borderTop: '1px solid #ccc',
    display: 'flex',
  },
]);

const intervalDelay = 100;
const intervalIncrement = 0.01;

const MigrateCodeComponent: FunctionComponent = () => {
  const layerHostId = useId('layerHost');
  const stackTokens: IStackTokens = { childrenGap: 51 };
  const wrapStackTokens: IStackTokens = { childrenGap: 5 };
  const navigate = useNavigate();
  const [convertClicked, setConvertClicked] = useState(false);
  const [appSelected, setAppSelected] = useState(false);
  const [percentComplete, setPercentComplete] = React.useState(0);

  React.useEffect(() => {
    const id = setInterval(() => {
      if (convertClicked) {
        setPercentComplete(intervalIncrement + percentComplete);
      }
    }, intervalDelay);
    return () => {
      clearInterval(id);
    };
  });

  const itemsBC: IBreadcrumbItem[] = [
    {
      text: 'Home',
      key: 'discover',
      onClick: () => {
        navigate('/home-migrate');
      },
    },
    {
      text: 'Migrate',
      key: 'migrate',
      onClick: () => {
        navigate('/migrate');
      },
    },
    {
      text: 'Migrate App',
      key: 'Migrate App',
      onClick: () => {
        console.log('');
      },
    },
  ];
  useEffect(() => {
    BreadcrumbService.setValue(itemsBC);
  }, []);

  const onAppSelected = (event: any, option: any, index: any): void => {
    const appNameSelected = option.text != null && option.text.length > 0;
    setConvertClicked(false);
    setAppSelected(appNameSelected);
  };

  let options = [];
  // @ts-ignore
  if (window.AppColection) {
    // @ts-ignore
    const appData = window.AppColection;
    // @ts-ignore
    options = appData.map((i) => {
      return { key: i.name, text: i.name };
    });
  }

  const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 300 },
  };

  return (
    <section
      style={{
        flex: 1,
        borderTop: '1px solid #ccc',
        display: 'flex',
        backgroundColor: '#F4F5F6',
      }}
    >
      <LayerHost id={layerHostId} className={layerHostClass}>
        <div className={contentHeaderClass}>
          <Text variant="xLargePlus" style={{ color: 'white' }}>
            Migrate to Java
          </Text>
        </div>
        <section
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            className="background-download-agent"
            style={{
              height: '100%',
              marginTop: '2px',
              marginLeft: '2px',
              marginBottom: '2px',
              marginRight: '2px',
              display: 'table-cell',
              verticalAlign: 'middle',
            }}
          >
            <Stack
              // horizontalAlign="space-evenly"
              // verticalAlign="center"
              verticalFill={false}
              horizontal
              tokens={wrapStackTokens}
              style={{
                height: '90%',
              }}
            >
              <div
                style={{
                  margin: '10px',
                  // width: "50%",
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Text>
                  To migrate mainframe resources into Java package, please
                  select an application. Then download the Java package pressing
                  the button below.
                </Text>
                <Text
                  style={{
                    marginTop: '11px',
                    marginBottom: '11px',
                  }}
                >
                  Please ensure all the Mainframe attributes like ISPF
                  libraries, JCL Jobcards and XMI files are uploaded before
                  starting migration process.
                </Text>
                <Dropdown
                  placeholder="Select application name"
                  label="Select application want to convert"
                  multiSelect={false}
                  options={options}
                  styles={dropdownStyles}
                  onChange={onAppSelected}
                />
                {!appSelected && convertClicked ? (
                  <Text variant="small" style={{ color: 'red' }}>
                    Please select an app
                  </Text>
                ) : (
                  <Text variant="small"> </Text>
                )}

                <div
                  style={{
                    width: 'calc(100%- 10px)',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {appSelected && convertClicked ? (
                    <div
                      style={{
                        marginTop: '41px',
                        flex: 1,
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        // eslint-disable-next-line prettier/prettier
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <ProgressIndicator
                        label="Migrating to Java"
                        description="Please wait until migration process is complete"
                        percentComplete={percentComplete}
                        styles={{
                          progressBar: {
                            backgroundColor:
                              theme.semanticColors.accentButtonBackground,
                            height: '15px',
                          },
                          progressTrack: {
                            height: '15px',
                            backgroundColor:
                              theme.semanticColors.disabledBackground,
                          },
                          itemProgress: { height: '15px' },
                        }}
                      />
                    </div>
                  ) : null}
                </div>

                {percentComplete >= 1 ? (
                  <>
                    <div
                      className="background-report-summary"
                      style={{
                        marginLeft: '-10px',
                        marginTop: '41px',
                        width: '800px',
                      }}
                    >
                      <Text variant="xLarge" style={{ color: 'black' }}>
                        Download Java package
                      </Text>
                    </div>
                    <div
                      style={{
                        paddingTop: '25px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <a href="./CFBOOKSV.zip" className="download" download>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <Text
                            variant="mediumPlus"
                            style={{ color: theme.palette.accent }}
                          >
                            ZIP
                          </Text>
                          <DownloadDocumentIcon
                            style={{
                              color: theme.semanticColors.accentButtonText,
                              fontSize: '45px',
                              width: '45px',
                              height: '45px',
                              paddingTop: '15px',
                              paddingBottom: '15px',
                              paddingRight: '20px',
                              paddingLeft: '20px',
                              border: `1px dashed ${theme.palette.blackTranslucent40}`,
                              borderRadius: '5px',
                              backgroundColor:
                                theme.semanticColors.accentButtonBackground,
                            }}
                          />
                          <Text variant="smallPlus">Download Java Package</Text>
                        </div>
                      </a>
                    </div>
                  </>
                ) : null}

                {!appSelected || !convertClicked ? (
                  <div
                    style={{
                      margin: '10px',
                      flex: 1,
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <div style={{ width: '300px', textAlign: 'center' }}>
                      <div
                        className="hov-pointer"
                        onClick={() => {
                          setConvertClicked(true);
                        }}
                      >
                        <div style={{}}>
                          <JavaLogoIcon
                            style={{
                              color: theme.semanticColors.accentButtonText,
                              fontSize: '65px',
                              width: '65px',
                              height: '65px',
                              paddingTop: '20px',
                              paddingBottom: '20px',
                              paddingRight: '30px',
                              paddingLeft: '30px',
                              border: `1px dashed ${theme.palette.blackTranslucent40}`,
                              borderRadius: '5px',
                              backgroundColor:
                                theme.semanticColors.accentButtonBackground,
                            }}
                          />
                        </div>
                        <div>
                          <Text variant="smallPlus">Convert to Java code</Text>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </Stack>
          </div>
        </section>
        <div className={contentFooterClass}>
          <Stack
            horizontal
            tokens={stackTokens}
            style={{
              float: 'right',
              lineHeight: '50px',
              alignItems: 'center',
            }}
          >
            <DefaultButton
              text="Previous"
              onClick={() => {
                navigate('/migrate');
              }}
              allowDisabledFocus
              style={{ height: '40px', fontSize: '18px' }}
            />

            <PrimaryButton
              text="Finish"
              disabled={!convertClicked || percentComplete < 1}
              onClick={() => {
                navigate('/home-migrate');
              }}
              style={{ height: '40px', fontSize: '18px' }}
            />
          </Stack>
        </div>
      </LayerHost>
    </section>
  );
};

export default MigrateCodeComponent;
