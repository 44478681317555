/* eslint-disable react/no-unescaped-entities */
import './setup-discovery-agent.scss';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FunctionComponent, useEffect, useState } from 'react';
import { LayerHost } from '@fluentui/react/lib/Layer';
import { useNavigate } from 'react-router-dom';
import {
  DefaultButton,
  IStackTokens,
  mergeStyles,
  PrimaryButton,
  Stack,
  Text,
} from '@fluentui/react';

import { useId } from '@fluentui/react-hooks';
import { getTheme } from '@fluentui/react/lib/Styling';

import { DownloadIcon } from '@fluentui/react-icons-mdl2';
import { IBreadcrumbItem } from '@fluentui/react/lib/Breadcrumb';
import BreadcrumbService from '../../service/breadcrumb.service';

const theme = getTheme();
const layerHostClass = mergeStyles({
  position: 'relative',
  marginLeft: '0%',
  marginRight: '0%',
  overflow: 'hidden',
  width: '100%',
  border: '1px solid #ccc',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.semanticColors.bodyBackground,
});

const contentHeaderClass = mergeStyles([
  {
    backgroundColor: theme.palette.black,
    color: theme.palette.white,
    lineHeight: '50px',
    padding: '0 20px',
  },
]);

const contentFooterClass = mergeStyles([
  {
    backgroundColor: theme.palette.blackTranslucent40,
    color: theme.palette.white,
    height: '50px',
    padding: '0 20px',
    borderTop: '1px solid #ccc',
    display: 'flex',
  },
]);

const contentClass = mergeStyles([
  {
    backgroundColor: theme.semanticColors.bodyDivider,
    color: theme.palette.white,
    lineHeight: '50px',
    padding: '0 10px',
    // borderBottom: "1px solid #ABB5FF",
  },
]);

const contentDownloadAgentClass = mergeStyles([
  {
    backgroundColor: theme.semanticColors.bodyDivider,
    color: theme.palette.white,
    lineHeight: '40px',
    padding: '0 10px',
    // borderBottom: "1px solid #9FB5D7",
  },
]);

const SetupDiscoveryAgentComponent: FunctionComponent = () => {
  const layerHostId = useId('layerHost');
  const stackTokens: IStackTokens = { childrenGap: 51 };
  const wrapStackTokens: IStackTokens = { childrenGap: 5 };
  const navigate = useNavigate();
  const [isComplete, setIsComplete] = useState(false);

  const itemsBC: IBreadcrumbItem[] = [
    {
      text: 'Home',
      key: 'Home',
      onClick: () => {
        navigate('/');
      },
    },
    {
      text: 'Discover',
      key: 'Discover',
      onClick: () => {
        console.log('');
      },
    },
  ];

  useEffect(() => {
    BreadcrumbService.setValue(itemsBC);
  }, []);

  return (
    <section
      style={{
        flex: 1,
        borderTop: '1px solid #ccc',
        display: 'flex',
        backgroundColor: '#F4F5F6',
      }}
    >
      <LayerHost id={layerHostId} className={layerHostClass}>
        <div className={contentHeaderClass}>
          <Text variant="xxLargePlus" style={{ color: 'white' }}>
            Download discovery agent
          </Text>
        </div>
        <section
          style={{
            flex: 0.5,
            display: 'flex',
            overflowX: 'scroll',
          }}
        >
          <Stack
            horizontalAlign="space-evenly"
            verticalAlign="center"
            verticalFill={false}
            horizontal
            tokens={wrapStackTokens}
            style={{
              paddingLeft: '5px',
            }}
          >
            <div className="background-setup-instruction">
              <div className={contentClass}>
                <Text variant="xLargePlus">Step 1</Text>
              </div>
              <div style={{ margin: '10px' }}>
                <Text>
                  When you download the agent, you will get a JCL file named
                  "DISCOVER .INSTALL.JCL". Pre-allocate a Fixed Block Physical
                  Sequential Dataset with LRECL=80 and upload
                  DISCOVER.INSTALL.JCL in 'Binary' format to all your LPARs.
                </Text>
              </div>
            </div>
            <div className="background-setup-instruction">
              <div className={contentClass}>
                <Text variant="xLargePlus">Step 2</Text>
              </div>
              <div style={{ margin: '10px' }}>
                <Text>
                  Submit this JCL on each LPAR by ensuring System Affinity so
                  the job runs on the targeted system in a sysplex configured
                  system. {'JOBPARM SYSAFF=<system>'} can be added to JCL before
                  submission.
                </Text>
              </div>
            </div>
            <div className="background-setup-instruction">
              <div className={contentClass}>
                <Text variant="xLargePlus">Step 3</Text>
              </div>
              <div style={{ margin: '10px' }}>
                <Text>
                  Ensure System Affinity Check with your MVS system admin on
                  usage of /*JOBPARM and add it to this JCL at the completion of
                  previous step. Verify the Job should end with status RC=0.
                </Text>
              </div>
            </div>
            <div className="background-setup-instruction">
              <div className={contentClass}>
                <Text variant="xLargePlus">Step 4</Text>
              </div>
              <div style={{ margin: '10px' }}>
                <Text>
                  Once all completed successfully till step three. A dataset
                  under your mainframe Userid HLQ will be created with following
                  name {'<Userid>.<LPARSystemID>.DISCOVER.JSON.XMI'}
                </Text>
              </div>
            </div>
            <div className="background-setup-instruction">
              <div className={contentClass}>
                <Text variant="xLargePlus">Step 5</Text>
              </div>
              <div style={{ margin: '10px' }}>
                <Text>
                  Finally after completing all previous steps successfully.
                  Proceed with download of XMI file from each LPAR where it is
                  created with 'Binary' Option
                </Text>
              </div>
            </div>
          </Stack>
        </section>
        <section
          style={{
            flex: 0.5,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            className="background-setup-download"
            style={{
              height: '102%',
              marginTop: '3px',
              marginLeft: '5px',
              marginBottom: '5px',
              marginRight: '5px',
              display: 'table-cell',
              verticalAlign: 'middle',
            }}
          >
            <div className={contentDownloadAgentClass}>
              <Text variant="xLargePlus" style={{ color: 'black' }}>
                Download Agent
              </Text>
            </div>
            <Stack
              // horizontalAlign="space-evenly"
              // verticalAlign="center"
              verticalFill={false}
              horizontal
              tokens={wrapStackTokens}
              style={{
                height: '80%',
              }}
            >
              <div
                style={{
                  margin: '10px',
                  width: '50%',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Text>
                  Download DISCOVER.INSTALL.JCL and run it in Mainframe system
                  by following the steps mentioned in the above section
                  sequentially. Finally we get XMI file for each LPAR/System.
                </Text>
              </div>
              <div
                style={{
                  margin: '10px',
                  textAlign: 'center',
                  verticalAlign: 'middle',
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <a
                  href="/DISCOVER.INSTALL.JCL"
                  download
                  onClick={() => setIsComplete(true)}
                >
                  <div style={{}}>
                    <DownloadIcon
                      style={{
                        color: theme.semanticColors.accentButtonText,
                        fontSize: '65px',
                        width: '65px',
                        height: '65px',
                        paddingTop: '20px',
                        paddingBottom: '20px',
                        paddingRight: '30px',
                        paddingLeft: '30px',
                        backgroundColor:
                          theme.semanticColors.accentButtonBackground,
                        border: `1px dashed ${theme.palette.blackTranslucent40}`,
                        borderRadius: '5px',
                      }}
                    />
                  </div>
                </a>
                <div>
                  <Text>Download agent</Text>
                </div>
              </div>
            </Stack>
          </div>
        </section>
        <div className={contentFooterClass}>
          <Stack
            horizontal
            tokens={stackTokens}
            style={{
              float: 'right',
              lineHeight: '50px',
              alignItems: 'center',
            }}
          >
            <DefaultButton
              text="Previous"
              onClick={() => {
                navigate('/app-qualifiers');
              }}
              allowDisabledFocus
              style={{ height: '40px', fontSize: '18px' }}
            />

            <PrimaryButton
              text="Continue"
              disabled={!isComplete}
              onClick={() => {
                navigate('/upload-agent-data');
              }}
              style={{ height: '40px', fontSize: '18px' }}
            />
          </Stack>
        </div>
      </LayerHost>
    </section>
  );
};

export default SetupDiscoveryAgentComponent;
