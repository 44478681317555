import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
  AzureThemeLight,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  AzureThemeDark,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  AzureThemeHighContrastLight,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  AzureThemeHighContrastDark,
} from '@fluentui/azure-themes';
import { ThemeProvider } from '@fluentui/react';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import App from './App';
import reportWebVitals from './reportWebVitals';

initializeIcons();
const theme = AzureThemeLight;
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
