import './application-qualifiers.scss';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import { LayerHost } from '@fluentui/react/lib/Layer';
import { useNavigate } from 'react-router-dom';
import {
  DefaultButton,
  IStackTokens,
  ITextFieldProps,
  mergeStyles,
  PrimaryButton,
  Stack,
  Text,
  TextField,
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { getTheme } from '@fluentui/react/lib/Styling';
import { createListItems, IExampleItem } from '@fluentui/example-data';
import { IBreadcrumbItem } from '@fluentui/react/lib/Breadcrumb';
import CustomLabel from '../../components/custom-label/custom-label';
import BreadcrumbService from '../../service/breadcrumb.service';
import DatalistV2, {
  IApplicationQualifier,
} from '../../components/datalist/datalist-v2';
import { ShimmerApplicationExample } from '../../components/shimmered-datalist/shimmered-datalist';

const theme = getTheme();
const layerHostClass = mergeStyles({
  position: 'relative',
  marginLeft: '0%',
  marginRight: '0%',
  overflow: 'hidden',
  width: '100%',
  border: '1px solid #ccc',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.semanticColors.bodyBackground,
});

const contentHeaderClass = mergeStyles([
  {
    backgroundColor: theme.palette.black,
    color: theme.palette.white,
    lineHeight: '50px',
    padding: '0 20px',
  },
]);

const contentFooterClass = mergeStyles([
  {
    backgroundColor: theme.palette.blackTranslucent40,
    color: theme.palette.white,
    height: '50px',
    padding: '0 20px',
    borderTop: '1px solid #ccc',
    display: 'flex',
  },
]);

const ApplicationQualifiersComponent: FunctionComponent = () => {
  const items: IExampleItem[] = createListItems(50);
  const layerHostId = useId('layerHost');
  const stackTokens: IStackTokens = { childrenGap: 51 };
  const stackTokensAdd: IStackTokens = { childrenGap: 21 };
  const wrapStackTokens: IStackTokens = { childrenGap: 2 };
  const navigate = useNavigate();
  const [appName, setAppName] = useState('');
  const [appQualifiers, setAppQualifiers] = useState('');
  const [appType, setAppType] = useState('');
  const [num, setNum] = useState(0);
  const [dataItems, setDataItems] = useState<IApplicationQualifier[]>([]);
  const [disableContinueButton, setDisableContinueButton] = useState(true);

  const randomIntFromInterval = (min: number, max: number): number => {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  };
  // const options: IDropdownOption[] = [
  //   { key: 'cloudfrm', text: 'CLOUDFRM' },
  //   { key: 'cloudfrm*', text: 'CLOUDFRM.**' },
  // ];

  // const dropdownStyles: Partial<IDropdownStyles> = {
  //   dropdown: { width: 300 },
  // };

  // const onAppTypeSelect = (event: any, option: any, index: any): void => {
  //   setAppType(option.text);
  // };

  const validateAppInfo = (appItem: IApplicationQualifier): string => {
    if (!appItem.name || !appItem.highLevelQualifier || !appItem.type) {
      return 'Please provide all three information';
    }
    const duplicateIdx = dataItems.findIndex(
      (item) => item.name === appItem.name,
    );
    if (duplicateIdx !== -1) return 'Duplicate application name';
    return '';
  };

  const validateAppName = (appItem: IApplicationQualifier): string => {
    if (!appItem.name) {
      return 'Please provide application name';
    }
    const duplicateIdx = dataItems.findIndex(
      (item) => item.name === appItem.name,
    );
    if (duplicateIdx !== -1) return 'Duplicate application name';
    return ' ';
  };

  const onAddAppItem = (): void => {
    const appItem: IApplicationQualifier = {
      key: `Item${dataItems.length}`,
      name: appName,
      highLevelQualifier: appQualifiers,
      type: appType,
    };
    const validMessage = validateAppInfo(appItem);
    if (!validMessage) {
      const updatedData = [...dataItems];
      updatedData.unshift(appItem);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.AppColection = updatedData;
      setDataItems(updatedData);
      setNum(0);
      setTimeout(() => {
        setNum(randomIntFromInterval(1, 100000));
      });
      setDisableContinueButton(false);
    }
  };

  const onDelete = (key: string): void => {
    const filterRecord = dataItems.filter((i) => {
      return i.key !== key;
    });
    setDataItems(filterRecord);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.AppColection = filterRecord;
    setNum(0);
    if (filterRecord.length > 0) {
      setTimeout(() => {
        setNum(randomIntFromInterval(1, 100000));
      });
    } else {
      setDisableContinueButton(true);
    }
  };

  const onResetAppDetails = (): void => {
    setAppName('');
    setAppQualifiers('');
    setAppType('');
  };

  const itemsBC: IBreadcrumbItem[] = [
    {
      text: 'Home',
      key: 'Home',
      onClick: () => {
        navigate('/');
      },
    },
    {
      text: 'Discover',
      key: 'Discover',
      onClick: () => {
        console.log('');
      },
    },
  ];

  useEffect(() => {
    BreadcrumbService.setValue(itemsBC);
  }, []);

  const onRenderLabel = (props: ITextFieldProps | undefined): ReactElement =>
    props ? (
      <CustomLabel id={props.label?.replace(' ', '')} {...props} />
    ) : (
      <div />
    );

  return (
    <section
      style={{
        flex: 1,
        borderTop: '1px solid #ccc',
        display: 'flex',
        backgroundColor: '#F4F5F6',
      }}
    >
      <LayerHost id={layerHostId} className={layerHostClass}>
        <div className={contentHeaderClass}>
          <Text variant="xLargePlus" style={{ color: 'white' }}>
            Mainframe application qualifiers
          </Text>
        </div>
        <section
          style={{
            flex: 0.5,
            display: 'flex',
            backgroundColor: theme.semanticColors.bodyBackground,
            margin: '5px',
          }}
        >
          <Stack
            horizontalAlign="space-evenly"
            verticalFill={false}
            horizontal={false}
            tokens={wrapStackTokens}
            style={{
              paddingLeft: '20px',
              width: '60%',
            }}
          >
            <div style={{ marginTop: '21px' }}>
              <TextField
                width={100}
                label="Application name"
                value={appName}
                onRenderLabel={onRenderLabel}
                ariaLabel="Name of the application for conducting all the operations"
                onChange={(e: any) => setAppName(e.target.value)}
                description="Please enter the application name"
              />
            </div>
            <div style={{ marginTop: '21px' }}>
              <TextField
                label="High level qualifiers"
                value={appQualifiers}
                placeholder="CLOUD*, CLOUDFRM.DEMO, CLOUDFRM.TRADING.BATCH"
                ariaLabel="High level qualifiers from Mainframe.
              For multiple qualifiers input them as comma separated text"
                onRenderLabel={onRenderLabel}
                onChange={(e: any) => setAppQualifiers(e.target.value)}
                description="Please enter all qualifiers separated by comma"
              />
            </div>
            <div style={{ marginTop: '21px' }}>
              <TextField
                label="Application Type"
                value={appType}
                ariaLabel="Please enter application type i.e. Batch/COBOL/DB2 & CICS/COBOL/VSAM"
                onRenderLabel={onRenderLabel}
                onChange={(e: any) => setAppType(e.target.value)}
                description="Please enter suitable type for your application"
              />
            </div>

            <div style={{ marginTop: '21px' }}>
              <Stack horizontal tokens={stackTokensAdd}>
                <DefaultButton
                  text="Reset"
                  onClick={onResetAppDetails}
                  allowDisabledFocus
                  style={{
                    height: '30px',
                    fontSize: '14px',
                    marginTop: '15px',
                    marginBottom: '15px',
                  }}
                />
                <PrimaryButton
                  text="Add"
                  onClick={onAddAppItem}
                  allowDisabledFocus
                  style={{
                    height: '30px',
                    fontSize: '14px',
                    marginTop: '15px',
                    marginBottom: '15px',
                  }}
                />
              </Stack>
            </div>
          </Stack>
          <div
            style={{
              display: 'flex',
              flex: '1',
              alignItems: 'flex-start',
              flexDirection: 'column',
              marginLeft: '51px',
              marginTop: '6px',
            }}
          >
            {/* <Text
              variant="xLarge"
              style={{
                display: 'block',
              }}
            >
              Instructions
            </Text> */}
            {/* <Text
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                justifySelf: 'center',
              }}
            >
              Provide the application name, high-level application qualifiers,
              and application type to configure the Discovery agent. It is
              possible to add multiple records in the application qualifiers
              grid. Then we can proceed with the next steps of setting up the
              discovery agent.
            </Text>
            <Text
              variant="xSmall"
              style={{
                display: 'block',
                fontWeight: 'bold',
                marginTop: '21px',
              }}
            >
              Note: This step is optional and we can skip this by pressing the
              Continue button
            </Text> */}
          </div>
        </section>
        <section
          style={{
            flex: 0.5,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'scroll',
            margin: '5px',
          }}
        >
          {num > 0 ? (
            <DatalistV2 DataRowItems={dataItems} onDelete={onDelete} />
          ) : (
            <ShimmerApplicationExample />
          )}
        </section>
        <div className={contentFooterClass}>
          <Stack
            horizontal
            tokens={stackTokens}
            style={{
              float: 'right',
              lineHeight: '50px',
              alignItems: 'center',
            }}
          >
            <DefaultButton
              text="Previous"
              onClick={() => {
                navigate('/');
              }}
              allowDisabledFocus
              style={{ height: '40px', fontSize: '18px' }}
            />

            <PrimaryButton
              text="Continue"
              allowDisabledFocus
              disabled={disableContinueButton}
              onClick={() => {
                navigate('/setup-discovery-agent');
              }}
              style={{ height: '40px', fontSize: '18px' }}
            />
          </Stack>
        </div>
      </LayerHost>
    </section>
  );
};

export default ApplicationQualifiersComponent;
