import React, { FunctionComponent, useState } from 'react';
import { Breadcrumb, IBreadcrumbItem } from '@fluentui/react/lib/Breadcrumb';
import BreadcrumbService from '../../service/breadcrumb.service';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type,no-underscore-dangle
const _returnUndefined = () => undefined;
const BreadcrumbComponent: FunctionComponent = () => {
  const [value, setValue] = useState([] as IBreadcrumbItem[]);
  BreadcrumbService.setValue = setValue;

  return (
    <div style={{ display: 'inline-block', marginLeft: '12px' }}>
      <Breadcrumb
        items={value}
        // Returning undefined to OnReduceData tells the breadcrumb not to shrink
        onReduceData={_returnUndefined}
        maxDisplayedItems={5}
        ariaLabel="Breadcrumb with static width"
        overflowAriaLabel="More items"
      />
    </div>
  );
};
export default BreadcrumbComponent;
