// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { FunctionComponent, useEffect } from 'react';
import { LayerHost } from '@fluentui/react/lib/Layer';
import {
  DocumentCard,
  DocumentCardDetails,
  DocumentCardImage,
  getTheme,
  IDocumentCardStyles,
  IIconProps,
  IStackTokens,
  mergeStyles,
  Stack,
  Text,
} from '@fluentui/react';
import { useNavigate } from 'react-router-dom';
import { useId } from '@fluentui/react-hooks';
import { ImageFit } from '@fluentui/react/lib/Image';
import { IBreadcrumbItem } from '@fluentui/react/lib/Breadcrumb';
import BreadcrumbService from '../../service/breadcrumb.service';

const theme = getTheme();
const layerHostClass = mergeStyles({
  position: 'relative',
  marginLeft: '0%',
  marginRight: '0%',
  overflow: 'hidden',
  width: '100%',
  border: '1px solid #ccc',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.semanticColors.bodyBackground,
});
const contentHeaderClass = mergeStyles([
  {
    backgroundColor: theme.palette.black,
    color: theme.palette.white,
    lineHeight: '50px',
    padding: '0 20px',
  },
]);

const contentDocumentDetailClass = mergeStyles([
  {
    padding: '10px 10px',
  },
]);

const contentFooterClass = mergeStyles([
  {
    backgroundColor: theme.palette.blackTranslucent40,
    color: theme.palette.white,
    height: '50px',
    padding: '0 20px',
    borderTop: '1px solid #ccc',
    display: 'flex',
  },
]);

const iconProps: Array<IIconProps> = [
  {
    iconName: 'ServerEnviroment',
    styles: {
      root: {
        color: theme.semanticColors.accentButtonBackground,
        fontSize: '140px',
        width: '140px',
        height: '140px',
      },
    },
  },
  {
    iconName: 'Calculator',
    styles: {
      root: {
        color: theme.semanticColors.accentButtonBackground,
        fontSize: '140px',
        width: '140px',
        height: '140px',
      },
    },
  },
  {
    iconName: 'Design',
    styles: {
      root: {
        color: '#d6ccc2',
        fontSize: '140px',
        width: '140px',
        height: '140px',
      },
    },
  },
];

const HomeAssessComponent: FunctionComponent = () => {
  const layerHostId = useId('layerHost');
  const wrapStackTokens: IStackTokens = { childrenGap: 30 };
  const navigate = useNavigate();

  const cardStyles: IDocumentCardStyles = {
    root: {
      display: 'inline-block',
      marginRight: 20,
      marginBottom: 20,
      width: 320,
      fontSize: '21px',
      cursor: 'pointer',
    },
  };

  const cardStylesDisable: IDocumentCardStyles = {
    root: {
      display: 'inline-block',
      marginRight: 20,
      marginBottom: 20,
      width: 320,
      fontSize: '21px',
      backgroundColor: theme.semanticColors.disabledBackground,
    },
  };

  const items: IBreadcrumbItem[] = [
    {
      text: 'Home',
      key: 'Home',
      onClick: () => {
        navigate('/home-assess');
      },
    },
  ];

  useEffect(() => {
    BreadcrumbService.setValue(items);
  }, []);

  return (
    <section
      style={{
        flex: 1,
        borderTop: '1px solid #ccc',
        display: 'flex',
        backgroundColor: '#F4F5F6',
      }}
    >
      <LayerHost id={layerHostId} className={layerHostClass}>
        <div className={contentHeaderClass}>
          <Text variant="xxLargePlus" style={{ color: 'white' }}>
            Discover mainframe components
          </Text>
        </div>
        <section
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Stack
            enableScopedSelectors
            horizontalAlign="space-evenly"
            verticalAlign="center"
            verticalFill
            wrap
            horizontal
            tokens={wrapStackTokens}
            style={{
              alignItems: 'center',
              paddingLeft: '20px',
              paddingRight: '20px',
            }}
          >
            <DocumentCard
              aria-label="Discover properties of mainframe"
              styles={cardStyles}
              onClick={() => {
                navigate('/app-qualifiers');
              }}
            >
              <DocumentCardImage
                height={175}
                imageFit={ImageFit.cover}
                iconProps={iconProps[0]}
              />
              <DocumentCardDetails className={contentDocumentDetailClass}>
                <Text variant="xxLarge">Discover</Text>
                <Text variant="medium">Discover property of mainframe</Text>
              </DocumentCardDetails>
            </DocumentCard>
            <DocumentCard
              aria-label="Assess cost of migrate"
              styles={cardStyles}
              onClick={() => {
                navigate('/assess-mainframe');
              }}
            >
              <DocumentCardImage
                height={175}
                imageFit={ImageFit.cover}
                iconProps={iconProps[1]}
              />
              <DocumentCardDetails className={contentDocumentDetailClass}>
                <Text variant="xxLarge">Assess</Text>
                <Text variant="medium">Assess cost of migrate</Text>
              </DocumentCardDetails>
            </DocumentCard>
            <DocumentCard
              aria-label="Migrate to cloudframe"
              styles={cardStylesDisable}
            >
              <DocumentCardImage
                height={175}
                imageFit={ImageFit.cover}
                iconProps={iconProps[2]}
              />
              <DocumentCardDetails className={contentDocumentDetailClass}>
                <Text variant="xxLarge">Migrate</Text>
                <Text variant="medium">Migrate to cloudframe</Text>
              </DocumentCardDetails>
            </DocumentCard>
          </Stack>
        </section>
        <div className={contentFooterClass} />
      </LayerHost>
    </section>
  );
};

export default HomeAssessComponent;
