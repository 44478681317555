/* eslint-disable prettier/prettier */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { ReactElement } from 'react';
import './App.css';
import {
  BrowserRouter,
  Routes, // instead of "Switch"
  Route,
} from 'react-router-dom';
import HomeComponent from './features/home/Home';
// eslint-disable-next-line max-len
import ApplicationQualifiersComponent from './features/application-qualifiers/application-qualifiers';
import HeaderComponent from './components/header/Header';
import FooterComponent from './components/footer/Footer';
import SetupDiscoveryAgentComponent from './features/setup-discovery-agent/setup-discovery-agent';
import BreadcrumbComponent from './components/breadcrumb/Breadcrumb';
import UploadAgentDataComponent from './features/upload-agent-data/upload-agent-data';
import AccessMainframeComponent from './features/assess-mainframe/assess-mainframe';
import MigrateComponent from './features/migrate/migrate';
import HomeAssessComponent from './features/home/home-assess';
import HomeMigrateComponent from './features/home/home-migrate';
import HelpComponent from './features/home/help';
import MigrateCodeComponent from './features/migrate-app/migrate-app';
import TestAppComponent from "./features/test-app/test-app";

const App = (): ReactElement => {
  return (
    <div
      style={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <BrowserRouter>
        <HeaderComponent />
        <BreadcrumbComponent />
        <Routes>
          <Route path="/" element={<HomeComponent />} />
          <Route
            path="/app-qualifiers"
            element={<ApplicationQualifiersComponent />}
          />
          <Route
            path="/setup-discovery-agent"
            element={<SetupDiscoveryAgentComponent />}
          />
          <Route
            path="/upload-agent-data"
            element={<UploadAgentDataComponent />}
          />
          <Route
            path="/assess-mainframe"
            element={<AccessMainframeComponent />}
          />
          <Route path="/help" element={<HelpComponent />} />
          <Route path="/migrate-code" element={<MigrateCodeComponent />} />
          <Route path="/home-assess" element={<HomeAssessComponent />} />
          <Route path="/home-migrate" element={<HomeMigrateComponent />} />
          <Route path="/migrate" element={<MigrateComponent />} />
          <Route path="/test-app" element={<TestAppComponent />} />
        </Routes>
      </BrowserRouter>
      <FooterComponent />
    </div>
  );
};

export default App;
