/* eslint-disable react/no-children-prop,react/jsx-no-comment-textnodes */
import React, { FunctionComponent, ReactElement } from 'react';
import {
  CompoundButton,
  DefaultButton,
  Dialog,
  DialogFooter,
  getTheme,
  IStackTokens,
  ITextFieldProps,
  mergeStyles,
  Panel,
  PanelType,
  PrimaryButton,
  ResponsiveMode,
  Stack,
  Text,
  TextField,
} from '@fluentui/react';
import { SettingsIcon } from '@fluentui/react-icons-mdl2';
import { useBoolean } from '@fluentui/react-hooks';
import { useNavigate } from 'react-router-dom';
import CustomLabel from '../custom-label/custom-label';

const theme = getTheme();
const contentClass = mergeStyles([
  {
    backgroundColor: theme.palette.themePrimary,
    color: theme.palette.white,
    lineHeight: '50px',
    padding: '0 20px',
  },
]);

const HeaderComponent: FunctionComponent = () => {
  const navigate = useNavigate();
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);

  const modelProps = {
    isBlocking: true,
    topOffsetFixed: true,
  };

  const stackTokens: IStackTokens = { childrenGap: 51 };
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const [hideDialogLib, { toggle: toggleHideDialogLib }] = useBoolean(true);

  const getStyles = (): object => {
    return {
      main: [
        {
          selectors: {
            '': {
              // Apply at root
              minWidth: '550px',
            },
          },
        },
      ],
    };
  };

  const onRenderLabel = (props: ITextFieldProps | undefined): ReactElement =>
    props ? (
      <CustomLabel id={props.label?.replace(' ', '')} {...props} />
    ) : (
      <div />
    );

  const getIspfHint = (): ReactElement => {
    return (
      <span>
        ISPF library names will be used to generate JCL, that when executed,
        will collect all necessary mainframe assets. If you know your ISPF
        library names for each ISPF, enter the names (copy and paste) into the
        table below. If you do not know your ISPF library names, follow
        <a
          href="/Finding_ISPF_Libraries.pdf"
          style={{ color: 'blue' }}
          download
        >
          {' these instructions '}
        </a>
        to determine the names, and then enter (copy and paste) into the table.
      </span>
    );
  };

  return (
    <header>
      <div className={contentClass}>
        <Text variant="xxLargePlus" style={{ color: 'white' }}>
          Azure Migrate
        </Text>
        {/* <Text variant="small" style={{ color: 'white', marginLeft: '5px' }}> */}
        {/*  Microsoft Azure migration */}
        {/* </Text> */}
        <SettingsIcon
          style={{
            fontSize: '38px',
            float: 'right',
            marginTop: '8px',
            cursor: 'pointer',
          }}
          onClick={openPanel}
        />
        <Panel
          headerText="Settings"
          isOpen={isOpen}
          onDismiss={dismissPanel}
          isLightDismiss
          type={PanelType.custom}
          customWidth="500px"
          closeButtonAriaLabel="Close"
        >
          <Stack horizontal={false} tokens={stackTokens}>
            <CompoundButton
              primary
              secondaryText="Configure JCL jobcard related settings."
              onClick={toggleHideDialog}
            >
              JCL Jobcard
            </CompoundButton>
            <CompoundButton
              primary
              secondaryText="Configure ISPF libraries related settings"
              onClick={toggleHideDialogLib}
            >
              ISPF Libraries
            </CompoundButton>
            <CompoundButton
              secondaryText="Documentation of discovery, access and migrate utility"
              onClick={() => {
                dismissPanel();
                navigate('/help');
              }}
            >
              Documentation
            </CompoundButton>
          </Stack>
        </Panel>
      </div>
      <Dialog
        hidden={hideDialog}
        dialogContentProps={{
          title: 'JCL Jobcard',
        }}
        onDismiss={toggleHideDialog}
        modalProps={modelProps}
        styles={getStyles}
      >
        <TextField
          multiline
          rows={5}
          style={{ width: '800px' }}
          placeholder={
            "//DISCOVER JOB (DISCOVER),'NAME',NOTIFY=&SYSUID,CLASS=D  \n" +
            '//*                                                       \n' +
            '//* '
          }
          label="Enter Job Card names"
          ariaLabel="Please enter Job Card names from Mainframe environment"
          onRenderLabel={onRenderLabel}
        />
        <DialogFooter>
          <PrimaryButton onClick={toggleHideDialog} text="Save" />
          <DefaultButton onClick={toggleHideDialog} text="Cancel" />
        </DialogFooter>
      </Dialog>
      <Dialog
        hidden={hideDialogLib}
        title="ISPF Libraries"
        onDismiss={toggleHideDialogLib}
        modalProps={modelProps}
        styles={getStyles}
      >
        <TextField
          multiline
          rows={7}
          style={{ width: '800px' }}
          placeholder={
            '//ISPMLIB DD DISP=SHR,DSN=ISP.SISPMENU  \n' +
            '//ISPPLIB DD DISP=SHR,DSN=ISP.SISPPENU  \n' +
            '//ISPTLIB DD DISP=SHR,DSN=ISP.SISPTENU  \n' +
            '//ISPSLIB DD DISP=SHR,DSN=ISP.SISPSLIB '
          }
          label="Enter ISPF library names"
          children={getIspfHint()}
          onRenderLabel={onRenderLabel}
        />
        <DialogFooter>
          <PrimaryButton onClick={toggleHideDialogLib} text="Save" />
          <DefaultButton onClick={toggleHideDialogLib} text="Cancel" />
        </DialogFooter>
      </Dialog>
    </header>
  );
};

export default HeaderComponent;
