import { useBoolean, useId } from '@fluentui/react-hooks';
import {
  Callout,
  DefaultButton,
  IButtonStyles,
  IconButton,
  IIconStyles,
  IStackStyles,
  IStackTokens,
  ITextFieldProps,
  Stack,
} from '@fluentui/react';

const CustomLabel = ({
  id,
  label,
  ariaLabel,
  children,
}: ITextFieldProps): JSX.Element => {
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] =
    useBoolean(false);
  const descriptionId: string = useId('description');
  const iconButtonId: string = useId('iconButton');
  const stackTokens: IStackTokens = {
    childrenGap: 4,
    maxWidth: 300,
  };
  const labelCalloutStackStyles: Partial<IStackStyles> = {
    root: { padding: 20 },
  };
  const iconButtonStyles: Partial<IButtonStyles> = {
    root: { marginBottom: -3 },
  };
  const iconProps = { iconName: 'Info' };

  return (
    <>
      <Stack horizontal verticalAlign="center" tokens={stackTokens}>
        <span id={id}>{label}</span>
        <IconButton
          id={iconButtonId}
          iconProps={iconProps}
          title="Info"
          ariaLabel="Info"
          onClick={toggleIsCalloutVisible}
          styles={iconButtonStyles}
        />
      </Stack>
      {isCalloutVisible && (
        <Callout
          target={`#${iconButtonId}`}
          setInitialFocus
          onDismiss={toggleIsCalloutVisible}
          ariaDescribedBy={descriptionId}
          role="alertdialog"
        >
          <Stack
            tokens={stackTokens}
            horizontalAlign="start"
            styles={labelCalloutStackStyles}
          >
            <span id={descriptionId}>{ariaLabel}</span>
            {children}
            <DefaultButton onClick={toggleIsCalloutVisible}>
              Close
            </DefaultButton>
          </Stack>
        </Callout>
      )}
    </>
  );
};

export default CustomLabel;
